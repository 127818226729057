import React from 'react';
import { Typography } from '@material-ui/core';

const specialStates = ['misiones'];

export const highlightState = (state: string) => {
  const isMatch = specialStates.some((item) => item.toLowerCase() === state.toLowerCase());

  return (
    <Typography variant="body1" component="span" color={isMatch ? 'primary' : 'inherit'}>
      {state}
    </Typography>
  );
};
