export enum MENU {
  ORDER = 1,
  INBOX = 2,
  SHIPPING = 3,
  DISPATCH = 4,
  DISPATCH_INQUIRY = 5,
  PROVINCES = 6,
  LOCALITIES = 7,
  IMPORTS = 8,
  ACCOUNTS = 9,
  REPORTING = 10,
  ERP_ORDERS = 11,
  PROCESS_MONITOR = 12,
  REPORT_PENDING_ANDREANI_INCOME = 13,
  COLLECTS = 14,
  DELIVERY_CONFIRMATION = 15,
  CANAL_OFF_LINE = 16,
  COURIERS = 17,
  MESSAGES = 18,
  MESSAGE_CONFIG = 19,
  TEMPLATE = 20,
  STOCK = 21,
  STOCK_LOGS = 22,
  REPORTING_SALES = 23,
  AGING_REPORT = 24,
  MESSAGE_CONFIG_ALERT = 25,
  ALERT = 33,
  REPORTING_OBJECT_SALES = 48,
  REPORTING_SALES_ALL_STATES = 68,
  REPORTING_LOGISTIC = 77,
  REPORTING_SALES_TRANSPORT = 78,
  REPORTING_ML = 82,
  LIQUIDATIONS = 85,
  REPORTING_SALES_TAXATION = 86,
  BUDGET = 87,
  STORE_PICKUP = 88,
  REPORTING_OBJETIVE_OFFLINE_SALE = 89,
  CARRIERS = 94,
  REPORTING_DASHBOARD = 98,
  REPORT_B2B_STOCK = 105,
  PICK_CONTROL = 106,
  REPORTING_ANDREANI_TRACKING = 107,
  VERIFY_ORDERS_BUTTON = 108,
  CLIENT_WITH_TURNS = 109,
  RECEIPTS = 112,
  CATALOG_PRODUCTS = 114,
  PRODUCT_STOCK_ERROR_SYNC = 113,
  STOCK_DAYS_REPORT = 115,
  CHARGEBACK_REPORT = 116
}
