import React, { useMemo } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Accounts } from './Accounts';
import { Cities } from './Cities';
import { PrivateRoute } from './components/PrivateRoute';
import { AccountProvider } from '../context/AccountProvider';
import { Imports } from './Imports';
import { InboxOrders } from './InboxOrders';
import { ERPOrder } from './ERPOrder';
import { Orders } from './Orders';
import { Products } from './Products';
import { States } from './States';
import { TagManager } from './TagManager';
import { Dispatches } from './Dispatches';
import { Collection } from './Collection';
import { Reporting } from './Reporting';
import { useGlobalContext } from '../hooks';
import { authorizeMenu } from '../utils';
import { userInfoType } from '../types';
import { OrderReport } from './OrderReport';
import { SterConfig } from './SterConfig';
import { Home } from './Home';
import { OrderDeliveryConfirmation } from './OrderDeliveryConfirmation';
import { ReportingPendingAndreaniIncome } from './ReportingAndreani';
import { MENU } from '../const';
import { CanalOffline } from './CanalOffOnline';
import { Couriers } from './Couriers';
import { ReportingSales } from './ReportingSales';
import { MessageConfig } from './MessageConfig';
import { Message } from './Messages';
import { MessagesTemplates } from './MessageTemplate';
import { Stock } from './Stock';
import { StockLogs } from './StockLogs';
import { Retool } from './Retool/components/retool';
import { Retool_menus } from '../interfaces/business/retool';
import { MessageConfigAlert } from './MessageConfigAlert';
import { Alert } from './Alerts';
import { ReportingObjectSales } from './ReportingObjectSales';
import { ReportingOrders } from './ReportingOrders';
import { ReportingLogistic } from './Reports';
import { ReportingTransport } from './ReportingTransport';
import { ReportingMl } from './ReportingMl';
import { Liquidations } from './Liquidations';
import { ReportingSalesTaxation } from './ReportingSalesTaxation';
import { Budget } from './Budget';
import { StorePickup } from './StorePickup';
import { ReportingObjectOfflineSales } from './ReportingObjectOfflineSales';
import { Carriers } from './Carriers';
import { ReportingDashboard } from './ReportingDashboard';
import { ReportB2BStock } from './ReportB2BStock';
import { PickControl } from './PickControl';
import { ReportingAdreaniTracking } from './ReportingAndreaniTracking';
import { ReportsQuicksight } from './ReportsQuicksight';
import { ClientsTurns } from './ClientsTurns';
import { Receipts } from './Receipts';
import { ProductStockSyncError } from './ProductStockSyncError';
import { Categories } from './Categories';
import { Brands } from './Brand';
import { StockDaysReport } from './StockDaysReport';
import { ChargebackReport } from './ChargebackReport';

interface RouteProps {
  isLogged: boolean;
}
const AccountsWithProvider = () => (
  <AccountProvider>
    <Accounts />
  </AccountProvider>
);

export const Routes = ({ isLogged }: RouteProps) => {
  const {
    userInfo
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();

  const privateRouterArray = useMemo(() => {
    const menusRetool = userInfo?.retool?.menus;

    const routes = [
      {
        path: '/orders/changestate',
        id: [MENU.DELIVERY_CONFIRMATION],
        authorize: () => authorizeMenu(userInfo, MENU.DELIVERY_CONFIRMATION),
        component: OrderDeliveryConfirmation
      },
      {
        path: '/orders',
        id: [MENU.ORDER],
        authorize: () => authorizeMenu(userInfo, MENU.ORDER),
        component: Orders
      },
      {
        path: '/inbox-orders',
        id: [MENU.INBOX],
        authorize: () => authorizeMenu(userInfo, MENU.INBOX),
        component: InboxOrders
      },
      {
        path: '/tag-manager',
        id: [MENU.SHIPPING],
        authorize: () => authorizeMenu(userInfo, MENU.SHIPPING),
        component: TagManager
      },
      {
        path: '/dispatches',
        id: [MENU.DISPATCH, MENU.DISPATCH_INQUIRY],
        authorize: () => authorizeMenu(userInfo, MENU.DISPATCH) || authorizeMenu(userInfo, MENU.DISPATCH_INQUIRY),
        component: Dispatches
      },
      {
        path: '/collect',
        id: [MENU.COLLECTS],
        authorize: () => authorizeMenu(userInfo, MENU.COLLECTS),
        component: Collection
      },
      {
        path: '/states',
        id: [MENU.PROVINCES],
        authorize: () => authorizeMenu(userInfo, MENU.PROVINCES),
        component: States
      },
      {
        path: '/cities',
        id: [MENU.LOCALITIES],
        authorize: () => authorizeMenu(userInfo, MENU.LOCALITIES),
        component: Cities
      },
      {
        path: '/imports/:importationTargetId',
        id: [MENU.IMPORTS],
        authorize: () => authorizeMenu(userInfo, MENU.IMPORTS),
        component: Imports
      },
      {
        path: '/accounts',
        id: [MENU.ACCOUNTS],
        authorize: () => authorizeMenu(userInfo, MENU.ACCOUNTS),
        component: AccountsWithProvider
      },
      {
        path: '/reporting',
        id: [MENU.REPORTING],
        authorize: () => authorizeMenu(userInfo, MENU.REPORTING),
        component: Reporting
      },
      {
        path: '/aging-report',
        id: [MENU.AGING_REPORT],
        authorize: () => authorizeMenu(userInfo, MENU.AGING_REPORT),
        component: OrderReport
      },
      {
        path: '/report_pending_andreani_income',
        id: [MENU.REPORT_PENDING_ANDREANI_INCOME],
        authorize: () => authorizeMenu(userInfo, MENU.REPORT_PENDING_ANDREANI_INCOME),
        component: ReportingPendingAndreaniIncome
      },
      {
        path: '/erp-orders',
        id: [MENU.ERP_ORDERS],
        authorize: () => authorizeMenu(userInfo, MENU.ERP_ORDERS),
        component: ERPOrder
      },
      {
        path: '/process_monitor',
        id: [MENU.PROCESS_MONITOR],
        authorize: () => authorizeMenu(userInfo, MENU.PROCESS_MONITOR),
        component: SterConfig
      },
      {
        path: '/canal-off-line',
        id: [MENU.CANAL_OFF_LINE],
        authorize: () => authorizeMenu(userInfo, MENU.CANAL_OFF_LINE),
        component: CanalOffline
      },
      {
        path: '/couriers',
        id: [MENU.COURIERS],
        authorize: () => authorizeMenu(userInfo, MENU.COURIERS),
        component: Couriers
      },
      {
        path: '/reporting-sales',
        id: [MENU.REPORTING_SALES],
        authorize: () => authorizeMenu(userInfo, MENU.REPORTING_SALES),
        component: ReportingSales
      },
      {
        path: '/messages/messages',
        id: [MENU.MESSAGES],
        authorize: () => authorizeMenu(userInfo, MENU.MESSAGES),
        component: Message
      },
      {
        path: '/messages/configuration',
        id: [MENU.MESSAGE_CONFIG],
        authorize: () => authorizeMenu(userInfo, MENU.MESSAGE_CONFIG),
        component: MessageConfig
      },
      {
        path: '/messages/templates',
        id: [MENU.TEMPLATE],
        authorize: () => authorizeMenu(userInfo, MENU.TEMPLATE),
        component: MessagesTemplates
      },
      {
        path: '/stock',
        id: [MENU.STOCK],
        authorize: () => authorizeMenu(userInfo, MENU.STOCK),
        component: Stock
      },
      {
        path: '/stock-logs',
        id: [MENU.STOCK],
        authorize: () => authorizeMenu(userInfo, MENU.STOCK),
        component: StockLogs
      },
      {
        path: '/messages/configuration_alert',
        id: [MENU.MESSAGE_CONFIG_ALERT],
        authorize: () => authorizeMenu(userInfo, MENU.MESSAGE_CONFIG_ALERT),
        component: MessageConfigAlert
      },
      {
        path: '/system/alerts',
        id: [MENU.ALERT],
        authorize: () => authorizeMenu(userInfo, MENU.ALERT),
        component: Alert
      },
      {
        path: '/reporting-goal-sales',
        id: [MENU.REPORTING_OBJECT_SALES],
        authorize: () => authorizeMenu(userInfo, MENU.REPORTING_OBJECT_SALES),
        component: ReportingObjectSales
      },
      {
        path: '/reporting-goal-offline-sales',
        id: [MENU.REPORTING_OBJETIVE_OFFLINE_SALE],
        authorize: () => authorizeMenu(userInfo, MENU.REPORTING_OBJETIVE_OFFLINE_SALE),
        component: ReportingObjectOfflineSales
      },
      {
        path: '/reporting-orders',
        id: [MENU.REPORTING_SALES_ALL_STATES],
        authorize: () => authorizeMenu(userInfo, MENU.REPORTING_SALES_ALL_STATES),
        component: ReportingOrders
      },
      {
        path: '/reporting-logistic',
        id: [MENU.REPORTING_LOGISTIC],
        authorize: () => authorizeMenu(userInfo, MENU.REPORTING_LOGISTIC),
        component: ReportingLogistic
      },
      {
        path: '/reporting-transport',
        id: [MENU.REPORTING_SALES_TRANSPORT],
        authorize: () => authorizeMenu(userInfo, MENU.REPORTING_SALES_TRANSPORT),
        component: ReportingTransport
      },
      {
        path: '/reporting-ml',
        id: [MENU.REPORTING_ML],
        authorize: () => authorizeMenu(userInfo, MENU.REPORTING_ML),
        component: ReportingMl
      },
      {
        path: '/liquidations',
        id: [MENU.LIQUIDATIONS],
        authorize: () => authorizeMenu(userInfo, MENU.LIQUIDATIONS),
        component: Liquidations
      },
      {
        path: '/reporting-sale-taxation',
        id: [MENU.REPORTING_SALES_TAXATION],
        authorize: () => authorizeMenu(userInfo, MENU.REPORTING_SALES_TAXATION),
        component: ReportingSalesTaxation
      },
      {
        path: '/budget',
        id: [MENU.BUDGET],
        authorize: () => authorizeMenu(userInfo, MENU.BUDGET),
        component: Budget
      },
      {
        path: '/store-pickup',
        id: [MENU.STORE_PICKUP],
        authorize: () => authorizeMenu(userInfo, MENU.STORE_PICKUP),
        component: StorePickup
      },
      {
        path: '/carriers',
        id: [MENU.CARRIERS],
        authorize: () => authorizeMenu(userInfo, MENU.CARRIERS),
        component: Carriers
      },
      {
        path: '/reports-quicksight',
        id: [MENU.REPORTING_DASHBOARD],
        authorize: () => authorizeMenu(userInfo, MENU.REPORTING_DASHBOARD),
        component: ReportsQuicksight
      },
      {
        path: '/reporting-dashboard',
        id: [MENU.REPORTING_DASHBOARD],
        authorize: () => authorizeMenu(userInfo, MENU.REPORTING_DASHBOARD),
        component: ReportingDashboard
      },
      {
        path: '/report-b2b-stock',
        id: [MENU.REPORT_B2B_STOCK],
        authorize: () => authorizeMenu(userInfo, MENU.REPORT_B2B_STOCK),
        component: ReportB2BStock
      },
      {
        path: '/pick-control',
        id: [MENU.PICK_CONTROL],
        authorize: () => authorizeMenu(userInfo, MENU.PICK_CONTROL),
        component: PickControl
      },
      {
        path: '/reporting-andreani-tracking',
        id: [MENU.REPORTING_ANDREANI_TRACKING],
        authorize: () => authorizeMenu(userInfo, MENU.REPORTING_ANDREANI_TRACKING),
        component: ReportingAdreaniTracking
      },
      {
        path: '/clients-turns',
        id: [MENU.CLIENT_WITH_TURNS],
        authorize: () => authorizeMenu(userInfo, MENU.CLIENT_WITH_TURNS),
        component: ClientsTurns
      },
      {
        path: '/receipts',
        id: [MENU.RECEIPTS],
        authorize: () => authorizeMenu(userInfo, MENU.RECEIPTS),
        component: Receipts
      },
      {
        path: '/products',
        id: [MENU.CATALOG_PRODUCTS],
        authorize: () => authorizeMenu(userInfo, MENU.CATALOG_PRODUCTS),
        component: Products
      },
      {
        path: '/categories',
        id: [MENU.CATALOG_PRODUCTS],
        authorize: () => authorizeMenu(userInfo, MENU.CATALOG_PRODUCTS),
        component: Categories
      },
      {
        path: '/brands',
        id: [MENU.CATALOG_PRODUCTS],
        authorize: () => authorizeMenu(userInfo, MENU.CATALOG_PRODUCTS),
        component: Brands
      },
      {
        path: '/product-stock-sync-error',
        id: [MENU.PRODUCT_STOCK_ERROR_SYNC],
        authorize: () => authorizeMenu(userInfo, MENU.PRODUCT_STOCK_ERROR_SYNC),
        component: ProductStockSyncError
      },
      {
        path: '/report-stock-days',
        id: [MENU.STOCK_DAYS_REPORT],
        authorize: () => authorizeMenu(userInfo, MENU.STOCK_DAYS_REPORT),
        component: StockDaysReport
      },
      {
        path: '/report-chargeback',
        id: [MENU.CHARGEBACK_REPORT],
        authorize: () => authorizeMenu(userInfo, MENU.STOCK_DAYS_REPORT),
        component: ChargebackReport
      }
    ];

    menusRetool?.forEach(({ menus }: Retool_menus) => {
      menus.forEach((menu) => {
        routes.push({
          path: menu.path,
          id: [menu.retool_menu_id],
          authorize: () => true,
          component: () => <Retool url={menu.retool_app || ''} />
        });
      });
    });

    return routes;
  }, [userInfo]);

  const render = useMemo(() => (userInfo?.menu?.length ? privateRouterArray.find((router) => userInfo?.menu?.length && router.id.includes(userInfo?.menu[0].menu.menu_id))?.path : '/'), [userInfo, privateRouterArray]);

  return !isLogged ? (
    <Switch>
      <Home />
    </Switch>
  ) : (
    <Switch>
      {privateRouterArray.map((data) => (
        <PrivateRoute key={`${data.path}`} path={data.path} authorize={data.authorize} component={data.component} redirect={render} />
      ))}
      <Redirect to={`${render}`} />
    </Switch>
  );
};
